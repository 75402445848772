<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "JinRumoXi",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(漠西场景会失败,退出漠西再初始环境,如果失败看菜单初始环境教程)(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到进入漠西/离开漠西页",
                            "1.进入漠西:可以在大世界任务后,下一步任务接进入漠西,执行漠西的任务",
                            "2.离开漠西:可以在漠西完成任务后,下一步任务接其他任务",
                        ],
                        img:[
                            "1.png",
                            "2.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#进入漠西后会自动寻找NPC领取马哨",
                            "#以上设置好,开始任务",
                        ],
                        img:[
                            "com/jinwudao.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
